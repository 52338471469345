// @ts-nocheck

import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

const SetupUpload = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("setupPage.pageTitle"));

  return (
    <Card sx={{ p: "24px 0px", width: 700, margin: "auto", mb: 6 }}>
      <Grid container pb={3}>
        <Grid item>
          <IconButton
            variant="text"
            component={RouterLink}
            to="/setup/info"
            aria-label="back"
            size="large"
            sx={{ mt: "-4px" }}
            data-testid="setup-back-button"
          >
            <ArrowBackIosOutlinedIcon
              color="primary"
              sx={{ fontWeight: 700, fontSize: "32px" }}
            />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h1">{t("setupPage.integrateHeader")}</Typography>
        </Grid>
      </Grid>
      <Box ml="54px" mr="54px" pb={6}>
        <Typography fontWeight="700">
          {t("setupPage.integrateExplainTxt")}
        </Typography>
        <Divider sx={{ pt: 6 }} />
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          p="12px 24px"
        >
          <Grid item>
            <Typography variant="h5Dark">
              {t("setupPage.auth0Integration")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              variant="text"
              component={Link}
              href="https://marketplace.auth0.com/integrations/verosint-signal-print?utm_source=verosint"
              target="_blank"
              aria-label="launch"
              data-testid="auth0-integrations-button"
            >
              <LaunchOutlinedIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          p="12px 24px"
        >
          <Grid item>
            <Typography variant="h5Dark">
              {t("setupPage.pingIntegration")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              variant="text"
              component={Link}
              href="https://support.pingidentity.com/s/marketplace-integration/a7iDo0000010zprIAA/verosint-account-fraud-detection-prevention-connector?utm_source=verosint"
              target="_blank"
              aria-label="launch"
              data-testid="setup-back-button"
            >
              <LaunchOutlinedIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          p="12px 24px"
        >
          <Grid item>
            <Typography variant="h5Dark">
              {t("setupPage.directIntegration")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              variant="text"
              component={Link}
              href="https://docs.verosint.com/docs/populating-signalprint#posting-user-data-to-the-signalprint-api"
              target="_blank"
              aria-label="launch"
              data-testid="setup-back-button"
            >
              <LaunchOutlinedIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
      </Box>
    </Card>
  );
};

export default SetupUpload;
