// @ts-nocheck
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import { useTheme } from "@mui/material/styles";
import storage from "local-storage-fallback";

const SetupStart = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("setupPage.pageTitle"));
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Card sx={{ p: "24px 64px", width: 700, margin: "auto", mb: 6 }}>
      <Grid container justifyContent="space-between" pb={4}>
        <Grid item xs={6} sx={{ textAlign: "center" }}>
          <Button
            component={RouterLink}
            to="/setup/upload"
            sx={{
              display: "block",
              margin: "4px 36px",
              padding: "18px 0",
              border: `1px solid ${theme.palette.border.default}`,
            }}
          >
            <Box>
              <CloudUploadOutlinedIcon
                color="primary"
                sx={{
                  fontSize: "32px",
                  width: "100px",
                  height: "100px",
                }}
              />
            </Box>
            <Typography fontWeight="700">
              {t("setupPage.uploadLogs")}
            </Typography>
          </Button>
          <Box
            sx={{
              width: "66%",
              textAlign: "center",
              margin: "auto",
              pt: 1,
            }}
          >
            <Typography fontSize="12px">
              {t("setupPage.uploadLogsTxt")}
            </Typography>
            <Button
              variant="text"
              target="_blank"
              href="https://docs.verosint.com/docs/populating-signalprint#upload-a-csv-file-or-integrate-with-a-partner"
            >
              {t("setupPage.seeDocs")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "center" }}>
          <Button
            component={RouterLink}
            to="/setup/integrations"
            sx={{
              display: "block",
              margin: "4px 36px",
              padding: "18px 0",
              border: `1px solid ${theme.palette.border.default}`,
            }}
          >
            <Box>
              <IntegrationInstructionsOutlinedIcon
                color="primary"
                sx={{
                  fontSize: "32px",
                  width: "100px",
                  height: "100px",
                }}
              />
            </Box>
            <Typography fontWeight="700">
              {t("setupPage.integrations")}
            </Typography>
          </Button>
          <Box
            sx={{
              width: "66%",
              textAlign: "center",
              margin: "auto",
              pt: 1,
            }}
          >
            <Typography fontSize="12px">
              {t("setupPage.integrationsTxt")}
            </Typography>
            <Button
              variant="text"
              target="_blank"
              href="https://docs.verosint.com"
            >
              {t("setupPage.seeDocs")}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Stack
        spacing={2}
        pb={4}
        alignItems="center"
        sx={{ textAlign: "center" }}
      >
        <Typography variant="h1">{t("setupPage.welcome")}</Typography>
        <Typography fontWeight="700">{t("setupPage.load")}</Typography>
        <Typography variant="p2">
          <Trans t={t} i18nKey="setupPage.noteAboutDemo">
            <strong>n</strong>t
          </Trans>
        </Typography>
      </Stack>
      <Box sx={{ textAlign: "center" }}>
        <Button
          color="hColor"
          sx={{ fontWeight: "700" }}
          onClick={() => {
            storage.setItem("newSignup", "true");
            navigate("/");
          }}
        >
          {t("setupPage.skipForNow")}
        </Button>
      </Box>
    </Card>
  );
};

export default SetupStart;
