import { RedirectLoginOptions, AppState, useAuth0 } from "@auth0/auth0-react";
import { getDeviceId } from "verosintjs";

function loginWithVerosintDeviceId(options?: RedirectLoginOptions<AppState>) {
    const { loginWithRedirect } = useAuth0();
    getDeviceId().then((id) => {
        return loginWithRedirect(mergeAuthParams(id, options));
    })
}

function mergeAuthParams(id: any, options?: RedirectLoginOptions<AppState>): RedirectLoginOptions<AppState> {
    if (options?.authorizationParams) {
        options.authorizationParams.verosint_deviceid = id;
    } else {
        options = { ...options, authorizationParams: { verosint_deviceid: id } };
    }
    return options;
}

export { loginWithVerosintDeviceId, mergeAuthParams };