/* eslint-disable no-unused-vars */

export type Accumulator = {
  [key: string]: any;
};

export enum ExpTabValue {
  Accounts = "accounts",
  Events = "events",
  IPs = "ips",
  Prints = "prints",
  Emails = "emails",
  Phones = "phones",
  Sessions = "sessions",
}
