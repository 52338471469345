// @ts-nocheck
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import notistackReducer from "../redux/Notistack/slice";
import userReducer from "../redux/User/slice";
import { fftIdApi } from "../services/443id";
import storage from "local-storage-fallback";

const rootReducer = combineReducers({
  user: userReducer,
  [fftIdApi.reducerPath]: fftIdApi.reducer,
  notistack: notistackReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "notistack/enqueueSnackbar",
          "notistack/closeSnackbar",
        ],
        ignoredPaths: ["notistack"],
      },
    }).concat(fftIdApi.middleware),
});

export default store;

export const BLUR_DEMO_KEY = "BLUR_FOR_DEMO";

export function IsLocalStorageBlurEnabled() {
  return storage.getItem(BLUR_DEMO_KEY) === "true";
}
