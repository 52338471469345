import { ExpTabValue } from "src/types/Common";

function useExplorerTableOptions(tabValue: ExpTabValue) {
  const isSelectable =
    tabValue !== ExpTabValue.Events && tabValue !== ExpTabValue.Sessions;
  const isSortable = tabValue === ExpTabValue.Events;
  return {
    search: false,
    thirdSortClick: false,
    draggable: false,
    showTitle: false,
    headerStyle: {
      fontWeight: 600,
      textTransform: "uppercase",
      padding: "18px 16px",
    },
    rowStyle: {
      fontSize: "14px",
      padding: 0,
    },
    tableLayout: "auto",
    pageSize: 25,
    pageSizeOptions: [25, 50, 100],
    emptyRowsWhenPaging: false,
    minBodyHeight: 300,
    selection: isSelectable,
    maxColumnSort: isSortable ? 1 : 0,
  };
}

export default useExplorerTableOptions;
